const FALLBACK_COLORS = ['#4F46E5', '#7C3AED', '#EC4899', '#F59E0B', '#10B981', '#3B82F6', '#6366F1', '#8B5CF6'];

// Keep track of used images for each search term
const usedImages: Record<string, Set<string>> = {};

function generateFallbackIcon(text: string): string {
  const color = FALLBACK_COLORS[Math.floor(Math.random() * FALLBACK_COLORS.length)];
  const letter = text.charAt(0).toUpperCase();
  
  const svg = `
    <svg width="1024" height="1024" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <rect width="1024" height="1024" fill="${color}" />
      <text
        x="512"
        y="512"
        font-size="480"
        font-family="system-ui, -apple-system, sans-serif"
        fill="white"
        text-anchor="middle"
        dominant-baseline="middle"
      >${letter}</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

async function searchOpenSymbols(query: string, excludeUrls: Set<string> = new Set()): Promise<string | null> {
  try {
    const response = await fetch(`https://www.opensymbols.org/api/v1/symbols/search?q=${encodeURIComponent(query)}`);
    if (!response.ok) {
      throw new Error('OpenSymbols API request failed');
    }
    
    const data = await response.json();
    
    if (data && Array.isArray(data) && data.length > 0) {
      // Filter out previously used images and invalid URLs
      const availableSymbols = data.filter((symbol: any) => 
        symbol.image_url && 
        !excludeUrls.has(symbol.image_url) &&
        symbol.image_url.startsWith('http')
      );
      
      if (availableSymbols.length === 0) {
        // If all images have been used or filtered out, reset the history
        usedImages[query] = new Set();
        return searchOpenSymbols(query);
      }

      // Randomize selection from available symbols
      const randomIndex = Math.floor(Math.random() * availableSymbols.length);
      const selectedSymbol = availableSymbols[randomIndex];
      const imageUrl = selectedSymbol.image_url;

      // Track used images
      if (!usedImages[query]) {
        usedImages[query] = new Set();
      }
      usedImages[query].add(imageUrl);

      return imageUrl;
    }
    return null;
  } catch (error) {
    console.error('Error fetching from OpenSymbols:', error);
    return null;
  }
}

export async function generateImage(prompt: string): Promise<string> {
  try {
    // Get currently used images for this prompt
    const excludeUrls = usedImages[prompt] || new Set();
    
    // Try to get a new image from OpenSymbols
    const imageUrl = await searchOpenSymbols(prompt, excludeUrls);
    if (imageUrl) {
      return imageUrl;
    }
    
    // If no image is found or there's an error, use fallback
    return generateFallbackIcon(prompt);
  } catch (error) {
    console.error('Error generating image:', error);
    return generateFallbackIcon(prompt);
  }
}

// Helper function to clear image history for a specific prompt
export function clearImageHistory(prompt: string): void {
  if (usedImages[prompt]) {
    delete usedImages[prompt];
  }
}