import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateImage, clearImageHistory } from '../utils/imageGeneration';
import { generatePageTitle } from '../utils/titleGeneration';
import { savePage, getSavedPages, type Page } from '../utils/storage';

export function EditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [pageName, setPageName] = useState('');
  const [choices, setChoices] = useState<Array<{ text: string; imageUrl: string }>>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number | null>(null);

  useEffect(() => {
    const pages = getSavedPages();
    const page = pages.find(p => p.id === id);
    if (page) {
      setPageName(page.name);
      setChoices(page.choices);
    } else {
      navigate('/');
    }
  }, [id, navigate]);

  const handleAlternateImage = async (index: number) => {
    if (isGenerating) return;
    
    try {
      setIsGenerating(true);
      const choiceText = choices[index].text;
      clearImageHistory(choiceText);
      
      const newImageUrl = await generateImage(choiceText);
      const newChoices = [...choices];
      newChoices[index] = {
        ...newChoices[index],
        imageUrl: newImageUrl
      };
      setChoices(newChoices);
    } catch (error) {
      console.error('Error generating alternate image:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && selectedChoiceIndex !== null) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newChoices = [...choices];
        newChoices[selectedChoiceIndex] = {
          ...newChoices[selectedChoiceIndex],
          imageUrl: reader.result as string
        };
        setChoices(newChoices);
        setSelectedChoiceIndex(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (choices.length > 0 && id) {
      const pages = getSavedPages();
      const pageIndex = pages.findIndex(p => p.id === id);
      if (pageIndex !== -1) {
        const updatedPage: Page = {
          ...pages[pageIndex],
          name: pageName.trim() || generatePageTitle(choices),
          choices
        };
        pages[pageIndex] = updatedPage;
        savePage(pages);
        navigate('/', { replace: true });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4 flex items-center">
        <button onClick={() => navigate(-1)} className="mr-4">
          ←
        </button>
        <h1 className="text-2xl font-bold">Edit Board</h1>
      </header>

      <main className="p-4 space-y-4">
        <div>
          <label className="block text-lg mb-2">Board Name</label>
          <input
            type="text"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            className="w-full p-2 rounded-lg border"
            placeholder="Enter board name"
          />
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          className="hidden"
        />

        <div>
          <h2 className="text-lg font-semibold mb-2">Current Choices:</h2>
          <div className="grid grid-cols-2 gap-4">
            {choices.map((choice, index) => (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow">
                <div className="relative">
                  <img 
                    src={choice.imageUrl} 
                    alt={choice.text} 
                    className="w-full h-40 object-cover"
                  />
                  <div className="absolute top-2 right-2 flex gap-2">
                    <button
                      onClick={() => handleAlternateImage(index)}
                      className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
                      aria-label="Get alternate image"
                      disabled={isGenerating}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setSelectedChoiceIndex(index);
                        fileInputRef.current?.click();
                      }}
                      className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
                      aria-label="Upload new image"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                        <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="p-2">
                  <input
                    type="text"
                    value={choice.text}
                    onChange={(e) => {
                      const newChoices = [...choices];
                      newChoices[index] = {
                        ...newChoices[index],
                        text: e.target.value
                      };
                      setChoices(newChoices);
                    }}
                    className="w-full p-2 rounded border"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <button 
          onClick={handleSave} 
          className="w-full nav-button mt-4"
        >
          Save Changes
        </button>
      </main>
    </div>
  );
}

export default EditPage;