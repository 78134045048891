export function generatePageTitle(choices: Array<{ text: string }>): string {
  if (choices.length === 0) return "Untitled Page";
  
  // If there are 3 or fewer choices, use all of them
  if (choices.length <= 3) {
    return choices.map(c => c.text).join(", ");
  }
  
  // If more than 3 choices, use first 2 and indicate more
  return `${choices[0].text}, ${choices[1].text} & ${choices.length - 2} more`;
}