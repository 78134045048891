import { useParams, useNavigate } from 'react-router-dom';
import { getSavedPages } from '../utils/storage';

export function ChoicesPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const pages = getSavedPages();
  const page = pages.find(p => p.id === id);

  const speak = (text: string) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  if (!page) {
    return (
      <div className="min-h-screen bg-gray-100 p-4">
        <div className="text-center">
          <h1 className="text-2xl text-gray-800">Board not found</h1>
          <button 
            onClick={() => navigate('/')} 
            className="nav-button mt-4"
          >
            Go Back Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4 flex items-center">
        <button onClick={() => navigate(-1)} className="mr-4">
          ←
        </button>
        <h1 className="text-2xl font-bold">{page.name}</h1>
      </header>

      <main className="p-4">
        <div className="grid grid-cols-2 gap-4">
          {page.choices.map((choice, index) => (
            <div
              key={index}
              className="choice-tile"
              onClick={() => speak(choice.text)}
            >
              <img src={choice.imageUrl} alt={choice.text} className="choice-image" />
              <p className="choice-text">{choice.text}</p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}