export interface Choice {
  text: string;
  imageUrl: string;
}

export interface Page {
  id: string;
  name: string;
  choices: Choice[];
  favorite?: boolean;
}

const STORAGE_KEY = 'aac_pages';

export function getSavedPages(): Page[] {
  try {
    const pagesString = localStorage.getItem(STORAGE_KEY);
    const pages = pagesString ? JSON.parse(pagesString) : [];
    
    return pages.sort((a: Page, b: Page) => {
      if (a.favorite && !b.favorite) return -1;
      if (!a.favorite && b.favorite) return 1;
      return b.id.localeCompare(a.id);
    });
  } catch (error) {
    console.error('Error getting saved pages:', error);
    return [];
  }
}

export function savePage(pages: Page[]): void {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(pages));
  } catch (error) {
    console.error('Error saving pages:', error);
    throw new Error('Failed to save pages');
  }
}

export function deletePage(id: string): void {
  try {
    const pages = getSavedPages();
    const updatedPages = pages.filter(page => page.id !== id);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPages));
  } catch (error) {
    console.error('Error deleting page:', error);
    throw new Error('Failed to delete page');
  }
}

export function toggleFavorite(id: string): void {
  try {
    const pages = getSavedPages();
    const pageIndex = pages.findIndex(page => page.id === id);
    if (pageIndex !== -1) {
      pages[pageIndex].favorite = !pages[pageIndex].favorite;
      localStorage.setItem(STORAGE_KEY, JSON.stringify(pages));
    }
  } catch (error) {
    console.error('Error toggling favorite:', error);
    throw new Error('Failed to toggle favorite');
  }
}