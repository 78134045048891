import { useNavigate } from 'react-router-dom';
import { getSavedPages, deletePage, toggleFavorite } from '../utils/storage';
import { useState } from 'react';

function HomePage() {
  const navigate = useNavigate();
  const [pages, setPages] = useState(getSavedPages());

  const handleDelete = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this board?')) {
      deletePage(id);
      setPages(getSavedPages());
    }
  };

  const handleEdit = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    navigate(`/edit/${id}`);
  };

  const handleToggleFavorite = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    toggleFavorite(id);
    setPages(getSavedPages());
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4">
        <h1 className="text-2xl font-bold">FastPix</h1>
      </header>

      <main className="p-4">
        <button
          onClick={() => navigate('/create')}
          className="w-full nav-button mb-4"
        >
          Create New Board
        </button>

        <div className="space-y-2">
          {pages.map((page) => (
            <div
              key={page.id}
              onClick={() => navigate(`/choices/${page.id}`)}
              className="bg-white p-4 rounded-lg shadow cursor-pointer hover:bg-gray-50 active:bg-gray-100 transition-colors duration-200 flex items-center group relative"
            >
              <button
                onClick={(e) => handleToggleFavorite(e, page.id)}
                className={`mr-3 ${
                  page.favorite ? 'text-yellow-500' : 'text-gray-400'
                } hover:scale-110 transition-transform duration-200`}
                aria-label={page.favorite ? "Remove from favorites" : "Add to favorites"}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </button>
              
              <div className="flex-grow">
                <h2 className="text-xl font-semibold">{page.name}</h2>
                <p className="text-gray-500">{page.choices.length} choices</p>
              </div>

              <div className="flex gap-2">
                <button
                  onClick={(e) => handleEdit(e, page.id)}
                  className="text-gray-600 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  aria-label="Edit board"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => handleDelete(e, page.id)}
                  className="text-red-600 p-2 rounded-full hover:bg-red-50 transition-colors duration-200"
                  aria-label="Delete board"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default HomePage;